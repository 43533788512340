import $ from "@modules/jquery";

/**
 * Insert autonative ad tags.
 */
const intro = $(".single:not(.hide-ads) .article-content p.lead, .single:not(.hide-ads) .article-content p:not([class]):not(:empty):has(> strong)").first();
const paragraphs = $(
  ".single:not(.hide-ads) .article-content p:not([class]):not(:empty):not(:has(> strong))"
);
paragraphs.splice(0,1);// Remove first two paragraphs.
const length = paragraphs.length;
let i = 1; // Skip 1 when placing divs.

if (intro.length > 0 && intro.text().length > 90) {
  i++;
  $(intro).after('<div id="adf-autonative"></div>');
}

if (length > 0) {
  paragraphs.each(function (index) {
    if (index + 1 === length) {
      return;
    }

    if (index % 3 === 0) {
      if (i > 3) {
        return;
      }

      const paragraph = $(this);
      // Skip if heading inside container.
      if (paragraph.parents(".wp-block-ms-container").length) {
        return;
      }

      // Only insert div when previous element is not the title.
      if (!paragraph.prev().is("h1")) {
        // Add first div if this is the first iteration and there's no intro.
        if (i === 1 && intro.length < 1) {
          i++;
          paragraph.after(`<div id="adf-autonative"></div>`);

          return;
        }

        // Add subsequent divs.
        if (i === 1) {
          paragraph.after(`<div id="adf-autonative"></div>`);
        } else {
          paragraph.after(`<div id="adf-autonative-${i}"></div>`);
        }
        i++;
      }
    }
  });
}
